import axios from 'axios'


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(function (response) {
    return response

}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    // If CSRF token expired
    if (error.response && error.response.status === 419) {
        window.location.reload()
    }

    return Promise.reject(error)
})


window.axios = axios
