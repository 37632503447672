import '~/bootstrap'
import Glide from '@glidejs/glide'


const map = (list, callback) => {
    let result = []

    for (let i = 0; i < list.length; i++) {
        result.push(callback.call(null, list[i], i))
    }

    return result
}

const each = (list, callback) => {
    for (let i = 0; i < list.length; i++) {
        callback.call(null, list[i], i)
    }
}

const mapWithKeys = (list, callback) => {
    let result = {}

    for (let i = 0; i < list.length; i++) {
        const [key, value] = callback.call(null, list[i], i)

        result[key] = value
    }

    return result
}



(function () {
    const header = document.getElementById('header')
    const siteLogo = document.getElementById('site-logo')
    const navMenu = document.getElementById('nav-content')
    const languageButton = document.getElementById('language-button')
    const languageDropdown = document.getElementById('language-dropdown')
    const menuList = document.querySelector('#nav-content > ul')

    languageButton.onclick = () => {
        const buttonPos = languageButton.getBoundingClientRect()

        const showing = languageDropdown.style.display != 'flex'

        languageDropdown.style.display = showing ? 'flex' : 'none'

        if (window.innerWidth < 1024) {
            languageDropdown.style.top = '69px'
            languageDropdown.style.left = 0
            languageDropdown.style.right = 0
            languageDropdown.style.height = buttonPos.top - 69 + 'px'
            menuList.style.opacity = showing ? 0 : 1
        } else {
            const dropdownPos = languageDropdown.getBoundingClientRect()

            languageDropdown.style.top = buttonPos.top + buttonPos.height + 'px'
            languageDropdown.style.left = 'auto'
            languageDropdown.style.left = buttonPos.right - dropdownPos.width + 'px'
            languageDropdown.style.height = 'auto'
            menuList.style.opacity = 1
        }
    }

    document.addEventListener('click', event => {
        if (! event.target.closest('#language-button, #language-dropdown')) {
            languageDropdown.style.display = 'none'
            menuList.style.opacity = 1
        }
    })

    const headerHeight = 64

    /**
     * @const {Array} menuItems - Sakārtoti dilstošā secībā, lai, pārbaudot `offset <= scrollPosition`, varētu sākt no tālākās sadaļas.
     * 
     * [
     *     {
     *         targetId: 'asdasd',
     *         menuItem: HTMLElement <a href="#asdasd">,
     *         targetElement: HTMLElement <div id="asdasd">,
     *     },
     *     ...
     * ]
     */
    const menuItems = map(document.querySelectorAll('#nav-content ul a.scroll-link'), link => {
        const targetId = link.getAttribute('href').substring(link.getAttribute('href').indexOf('#') + 1)

        return {
            targetId,
            link,
            targetElement: document.getElementById(targetId)
        }
    }).sort((a, b) => (a.targetElement.offsetTop > b.targetElement.offsetTop) ? -1 : ((b.targetElement.offsetTop > a.targetElement.offsetTop) ? 1 : 0))


    function getScrollOffset(sectionElement) {
        // Ja nāk klāt vēl sadaļas ar max-h-full, jātaisa te citādāk
        return sectionElement.id == 'contacts' ? sectionElement.offsetTop : sectionElement.offsetTop - headerHeight
    }

    function applyHeaderStyles(section) {
        if (section == 'start') {
            const textColor = isMainMenuOpen() ? '#000' : document.querySelector('section')?.getAttribute('data-color')

            header.className = 'fixed w-full z-30 top-0' + (isMainMenuOpen() ? ' bg-white backdrop-blur shadow' : '')
            languageDropdown.className = 'text-lg text-black' + (isMainMenuOpen() ? '' : ' bg-white backdrop-blur shadow')
            siteLogo.className = 'h-8 xl:h-16' + (textColor?.startsWith('#f') ? ' filter invert' : '')
            header.style.color = textColor

        } else {
            header.className = 'fixed w-full z-30 top-0 text-black bg-white backdrop-blur shadow'
            languageDropdown.className = 'text-lg' + (isMainMenuOpen() ? '' : ' bg-white backdrop-blur shadow')
            siteLogo.className = 'h-6 xl:h-8'
            header.style.color = null
        }
    }

    function handleScrollEvent() {
        const scrollPosition = window.scrollY

        if (scrollPosition > 10) {
            siteLogo.classList.remove('xl:h-16');


            let foundMenuItem = false

            for (let i = 0; i < menuItems.length; i++) {
                const menuItem = menuItems[i]

                menuItem.link.classList.remove('active')

                if (! foundMenuItem) {
                    const offset = getScrollOffset(menuItem.targetElement)

                    // Šis ifs uz katru scroll event izpildās tikai vienreiz.
                    if (offset - 1 <= scrollPosition) {
                        foundMenuItem = true

                        menuItem.link.classList.add('active')

                        applyHeaderStyles(menuItem.targetId)
                    }
                }
            }

            if (! foundMenuItem) {
                applyHeaderStyles('default')
            }

        } else {
            applyHeaderStyles('start')
        }
    }


    document.addEventListener('scroll', handleScrollEvent)
    window.addEventListener('resize', handleScrollEvent)
    window.addEventListener('load', handleScrollEvent)




    // Toggle dropdown menu
    const navMenuToggle = document.getElementById('nav-toggle');

    function check(e) {
        var target = (e && e.target) || (event && event.srcElement);

        //Nav Menu
        if (! checkParent(target, navMenu)) {
            // click NOT on the menu
            if (checkParent(target, navMenuToggle)) {
                // click on the link
                if (! isMainMenuOpen()) {
                    showMainMenu()
                } else {
                    hideMainMenu()
                }
            } else {
                // click both outside link and outside menu, hide menu
                hideMainMenu()
            }
            
            handleScrollEvent()
        }
    }

    function checkParent(t, elm) {
        while (t.parentNode) {
            if (t == elm) {
                return true;
            }
            t = t.parentNode;
        }
        return false;
    }

    document.onclick = check;



    function showMainMenu() {
        navMenu.classList.remove('hidden')
    }

    function hideMainMenu() {
        navMenu.classList.add('hidden')
    }

    function isMainMenuOpen() {
        return window.innerWidth < 1024 && !navMenu.classList.contains('hidden')
    }


    each(header.querySelectorAll('a.scroll-link'), link => {
        link.addEventListener('click', event => {

            const hashIndex = link.getAttribute('href').indexOf('#')

            if (hashIndex == -1) {
                return
            }

            event.preventDefault()

            const targetId = link.getAttribute('href').substring(hashIndex + 1)

            let targetScrollOffset = 0

            if (targetId) {
                const targetElement = document.getElementById(targetId)

                if (targetElement) {
                    targetScrollOffset = getScrollOffset(targetElement)
                }
            }

            if (targetScrollOffset !== null) {
                window.scrollTo({
                    top: targetScrollOffset,
                    behavior: 'smooth'
                })

                hideMainMenu()
            }
        })
    })

})();


window.contactFormLoading = false

window.submitContactForm = segmentKey => {
    if (window.contactFormLoading) {
        return
    }

    const contactForm = document.getElementById(segmentKey)

    const successMessage = contactForm.querySelector('[data-contact-us-success-message]')
    const errorMessage = contactForm.querySelector('[data-contact-us-error-message]')
    const errorMessageContent = contactForm.querySelector('[data-contact-us-success-message-content]')

    const messageTextarea = contactForm.querySelector('[name="message-content"]')

    successMessage.style.display = 'none'
    errorMessage.style.display = 'none'
    window.contactFormLoading = true

    axios.post(window.sendMessageUrl, {
        name: contactForm.querySelector('[name="sender-name"]').value,
        email: contactForm.querySelector('[name="sender-email"]').value,
        page_id: contactForm.querySelector('[name="page_id"]').value,
        page_url: contactForm.querySelector('[name="page_url"]').value,
        content: messageTextarea.value,
    }).then(response => {
        if (response.data.success) {
            successMessage.style.display = 'flex'
            messageTextarea.value = ''

        } else if (response.data.error) {
            errorMessage.style.display = 'flex'
            errorMessageContent.innerHTML = response.data.error
        }

    }).catch(error => {
        errorMessage.style.display = 'flex'
        errorMessageContent.innerHTML = error.response.data?.error ?? 'Error'
    }).then(() => {
        window.contactFormLoading = false
    })
}




/**
 * Image slide shows
 */
for (const gallery of window.galleries) {
    const isFullScreen = gallery.height == 'full'
    const selector = '.glide-' + gallery.page_segment_id

    const breakpoints = isFullScreen ? {} : {
        1400: {
            perView: 6
        },
        1200: {
            perView: 5
        },
        1000: {
            perView: 4
        },
        900: {
            perView: 3
        },
        800: {
            perView: 2
        },
        480: {
            perView: 1
        }
    }

    const glide = new Glide(selector, {
        type: 'carousel',
        perView: isFullScreen ? 1 : 6,
        gap: 0,
        animationDuration: 1000,
        hoverpause: !isFullScreen,
        autoplay: 4500, // ms'
        breakpoints: breakpoints
    })

    glide.mount()
}
